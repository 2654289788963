
.btn-header,
.link-primary:focus,
.link-primary:hover{   
    border-radius: 5px;
    color:var(--bs-primary); 
    padding: 0 .7rem;         
}

.link-primary:focus,
.link-primary:hover{    
    border: 1px solid var(--bs-primary);   
  
}

.card-Contato{
    width: 58%;
    margin: 2rem 20rem 0;   
}

.cardBody-Contato{
    padding-top: 0;
    margin:0 4px;
}

.text-Titulo{    
    background-color:var(--bs-form);  
    padding-top: 8px;    
}

.text-Contato{
    color: var(--bs-white);
    margin: .5rem;
}

.text-Subtitulo{
    padding: 2rem 0 0;   
}


@media(max-width:768px) {
    .card-Contato{ 
        width:100%;       
        margin: 0;  
    }
}

